import React, { useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import ResponsiveContext from '../Responsive/context';

import Row from '../Container/row';
import Col from '../Container/column';
import Link from '../Links';

import './copyright.css';

export default function Copyright() {
  const { windowXL, windowSM } = useContext(ResponsiveContext);
  const data = useStaticQuery(graphql`
    query CopyrightQuery {
      wp {
        ...SiteName
      }
      wpMenu(databaseId: { eq: 75 }) {
        ...MenuData
      }
    }
  `);
  const menu = data.wpMenu.menuItems.nodes;
  return (
    <div className="copyrightOuter" style={outerStyle}>
      <Row align="center" justify={windowXL ? 'space-between' : 'center'}>
        <Col className="footerCopyright" xs={{ width: `auto` }}>
          <p>
            Copyright &copy; {new Date().getFullYear()}
            {` `}
            {data.wp.generalSettings.title}. All rights reserved.
          </p>
        </Col>
        <Col xs={{ width: `auto` }}>
          <ul
            className="footerMenu"
            style={{
              display: `flex`,
              flexDirection: windowSM ? `row` : `column`,
              alignItems: `center`,
            }}
          >
            {menu.map((item, i) => {
              const key = `footerMenu${i}`;
              return (
                <li key={key}>
                  <Link to={item.url}>{item.label}</Link>
                </li>
              );
            })}
          </ul>
        </Col>
      </Row>
    </div>
  );
}

const outerStyle = {
  borderTop: `1px solid rgba(0,0,0,0.2)`,
};
